import {
  ref,
  watch,
  computed,
  onMounted,
} from '@vue/composition-api'
import moment from 'moment'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import store from '@/store'

export default function useMystoreList() {
  // Use toast
  const toast = useToast()

  const refMystoreListTable = ref(null)
  const yearFilter = ref(moment().format('YYYY'))
  const monthFilter = ref(moment().format('MM'))
  const searchTextFilter = ref(null)
  const stateFilter = ref({ label: '전체', value: false })
  const selectedStoreList = ref([])
  const selectedStore = ref(null)
  const isAllModalOpen = ref(false)
  const isPartialModalOpen = ref(false)
  const isOneModalOpen = ref(false)
  const saleStoreCount = ref(0)
  const storeTotalAmount = ref(0)
  const sampleTotalAmount = ref(0)

  const stateList = [
    { label: '전체', value: false },
    { label: '정산', value: true },
  ]

  // Table Handlers
  const tableColumns = [
    { key: 'url_path', label: '스토어 명', sortable: true },
    {
      key: 'sale_amount',
      label: '총 판매금액',
      sortable: true,
      formatter: val => `${val}`,
    },
    {
      key: 'settlement_amount',
      label: '총 정산금액',
      sortable: false,
      formatter: val => `${val}`,
    },
    {
      key: 'remain_settlement_amount',
      label: '남은 정산금액',
      sortable: false,
      formatter: val => `${val}`,
    },
    { key: 'action' },
  ]
  const perPage = ref(10)
  const totalStore = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('sale_amount')
  const isSortDirDesc = ref(true)

  const dataMeta = computed(() => {
    const localItemsCount = refMystoreListTable.value ? refMystoreListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalStore.value,
    }
  })

  const getSettlementSummary = () => {
    store
      .dispatch('app-invoice/getSettlementSummary', {
        year: yearFilter.value,
        month: monthFilter.value,
      })
      .then(response => {
        const results = response.data.data
        saleStoreCount.value = results.sale_myshop_count
        storeTotalAmount.value = results.sale_myshop_amount
        sampleTotalAmount.value = results.sale_sample_amount
      })
      .catch(() => {
        saleStoreCount.value = 0
        storeTotalAmount.value = 0
        sampleTotalAmount.value = 0
        toast({
          component: ToastificationContent,
          props: {
            title: '통계 불러오기에 실패했습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const refetchData = () => {
    refMystoreListTable.value.refresh()
    getSettlementSummary()
  }

  const fetchMystoreList = (ctx, callback) => {
    store
      .dispatch('app-invoice/fetchMystoreList', {
        page: currentPage.value,
        year: yearFilter.value,
        month: monthFilter.value,
        search_settle_myshop: stateFilter.value.value,
        search_text: searchTextFilter.value,
        sort_by: sortBy.value,
        is_desc: isSortDirDesc.value,
      })
      .then(response => {
        const { results, count } = response.data.data
        callback(results)
        totalStore.value = count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: '스토어 내역이 없습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const modalClose = () => {
    isAllModalOpen.value = false
    isPartialModalOpen.value = false
    isOneModalOpen.value = false
    selectedStoreList.value = []
    selectedStore.value = null
  }

  const settlementMargin = ({ year, month, mystoreList }) => {
    store
      .dispatch('app-invoice/settlementMargin', {
        year,
        month,
        mystore_url_list: mystoreList,
      })
      .then(() => {
        currentPage.value = 1
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: '정산 진행에 성공했습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'success',
          },
        })
        modalClose()
      })
      .catch(() => {
        refetchData()
        toast({
          component: ToastificationContent,
          props: {
            title: '정산 진행에 실패했습니다.',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
        modalClose()
      })
  }

  const settlementAll = () => {
    settlementMargin({
      year: yearFilter.value,
      month: monthFilter.value,
      mystoreList: 'ALL',
    })
  }

  const settlementSelect = () => {
    if (!selectedStoreList.value.length) {
      toast({
        component: ToastificationContent,
        props: {
          title: '선택된 스토어가 없습니다.',
          icon: 'AlertTriangleIcon',
          variant: 'danger',
        },
      })
      return
    }
    settlementMargin({
      year: yearFilter.value,
      month: monthFilter.value,
      mystoreList: selectedStoreList.value.map(val => val.url_path),
    })
  }

  const settlementEach = mystoreUrlPath => {
    settlementMargin({
      year: yearFilter.value,
      month: monthFilter.value,
      mystoreList: [mystoreUrlPath],
    })
  }

  const openSettleAllModal = () => {
    isAllModalOpen.value = true
    isPartialModalOpen.value = false
    isOneModalOpen.value = false
  }

  const openSettlePartialModal = () => {
    isAllModalOpen.value = false
    isPartialModalOpen.value = true
    isOneModalOpen.value = false
  }

  const openSettleOneModal = val => {
    selectedStore.value = val
    isAllModalOpen.value = false
    isPartialModalOpen.value = false
    isOneModalOpen.value = true
  }

  const onRowSelected = items => {
    selectedStoreList.value = items
  }

  const resetSelectedStore = () => {
    selectedStore.value = null
  }

  const resetSelectedStoreList = () => {
    selectedStoreList.value = []
  }

  watch([
    currentPage,
  ], () => {
    refetchData()
  })

  onMounted(
    () => {
      getSettlementSummary()
    },
  )

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  return {
    fetchMystoreList,
    tableColumns,
    perPage,
    currentPage,
    totalStore,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refMystoreListTable,
    stateList,
    selectedStoreList,
    selectedStore,
    saleStoreCount,
    storeTotalAmount,
    sampleTotalAmount,
    isAllModalOpen,
    isPartialModalOpen,
    isOneModalOpen,

    yearFilter,
    monthFilter,
    searchTextFilter,
    stateFilter,

    refetchData,
    settlementAll,
    settlementSelect,
    settlementEach,
    openSettleAllModal,
    openSettlePartialModal,
    openSettleOneModal,
    modalClose,
    onRowSelected,
    resetSelectedStore,
    resetSelectedStoreList,
  }
}
