<template>
  <b-card class="p-100">
    <b-card-header>
      <h2 class="pb-50">
        검색 필터
      </h2>
    </b-card-header>
    <b-card-body>
      <b-row>
        <b-col md="4">
          <b-form-group label="날짜">
            <v-select
              :value="selectedDate"
              label="label"
              :options="selectDateList"
              @input="updateDateFilter"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="상태">
            <v-select
              :value="stateFilter"
              label="label"
              :options="stateList"
              @input="val => $emit('update:stateFilter', val)"
            />
          </b-form-group>
        </b-col>
        <b-col md="4">
          <b-form-group label="스토어 검색">
            <b-form-input
              :value="searchText"
              name="searchText"
              class="d-inline-block"
              placeholder="검색 텍스트 입력"
              @input="(val) => $emit('update:searchTextFilter', val)"
            />
          </b-form-group>
        </b-col>
      </b-row>
      <b-form-group class="mt-1">
        <b-button
          class="mr-2"
          variant="gradient-primary"
          @click="$emit('search')"
        >
          검색
        </b-button>
        <b-button
          class="mr-2"
          variant="gradient-success"
          @click="$emit('settlementAll')"
        >
          전체정산
        </b-button>
        <b-button
          variant="gradient-primary"
          @click="$emit('settlementPartial')"
        >
          선택정산
        </b-button>
      </b-form-group>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard,
  BCardHeader,
  BCardBody,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BButton,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ref, computed } from '@vue/composition-api'

import moment from 'moment'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardHeader,
    BCardBody,
    BFormGroup,
    BFormInput,
    BButton,
    vSelect,
  },
  props: {
    yearFilter: {
      type: [String, null],
      default: null,
    },
    monthFilter: {
      type: [String, null],
      default: null,
    },
    searchTextFilter: {
      type: [String, null],
      default: null,
    },
    stateFilter: {
      type: [Object, null],
      default: null,
    },
    stateList: {
      type: Array,
      required: true,
    },
  },
  setup(props, { emit }) {
    moment.addRealMonth = function addRealMonth(d) {
      const fm = moment(d).add(1, 'M')
      const fmEnd = moment(fm).endOf('month')
      return d.date() !== fm.date() && fm.isSame(fmEnd.format('YYYY-MM-DD')) ? fm.add(1, 'd') : fm
    }
    const searchText = ref('')
    const startDate = moment('2021-12-01')
    const endDate = moment(moment().format('YYYY-MM-01'))
    const inputYear = computed(() => props.yearFilter)
    const inputMonth = computed(() => props.monthFilter)
    const selectedDate = computed(() => `${inputYear.value}-${inputMonth.value}`)

    const duration = moment.duration(endDate.diff(startDate))

    const selectDateList = [
      {
        label: '2021-12',
        value: '2021-12',
      },
      {
        label: '2022-01',
        value: '2022-01',
      },
      {
        label: '2022-02',
        value: '2022-02',
      },
      {
        label: '2022-03',
        value: '2022-03',
      },
      {
        label: '2022-04',
        value: '2022-04',
      },
      {
        label: '2022-05',
        value: '2022-05',
      },
      {
        label: '2022-06',
        value: '2022-06',
      },
      {
        label: '2022-07',
        value: '2022-07',
      },
      {
        label: '2022-08',
        value: '2022-08',
      },
      {
        label: '2022-09',
        value: '2022-09',
      },
      {
        label: '2022-10',
        value: '2022-10',
      },
      {
        label: '2022-11',
        value: '2022-11',
      },
      {
        label: '2022-12',
        value: '2022-12',
      },
      {
        label: '2023-01',
        value: '2023-01',
      },
      {
        label: '2023-02',
        value: '2023-02',
      },
      {
        label: '2023-03',
        value: '2023-03',
      },
      {
        label: '2023-04',
        value: '2023-04',
      },
      {
        label: '2023-05',
        value: '2023-05',
      },
      {
        label: '2023-06',
        value: '2023-06',
      },
      {
        label: '2023-07',
        value: '2023-07',
      },
    ]
    // let updateDate = startDate
    // for (let i = 0; i <= duration.months(); i += 1) {
    //   selectDateList.push({
    //     label: updateDate.format('YYYY-MM'),
    //     value: updateDate.format('YYYY-MM'),
    //   })
    //   updateDate = moment.addRealMonth(updateDate)
    //   console.log(updateDate)
    // }

    const updateDateFilter = value => {
      const dateFilter = moment(value.value)
      emit('update:yearFilter', dateFilter.format('YYYY'))
      emit('update:monthFilter', dateFilter.format('MM'))
    }

    return {
      selectDateList,
      searchText,
      selectedDate,

      updateDateFilter,
    }
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
