<template>

  <section>

    <mystore-settlement-all-modal
      :is-open="isAllModalOpen"
      :year="yearFilter"
      :month="monthFilter"
      @submit="settlementAll"
      @cancel="modalClose"
    />

    <mystore-settlement-partial-modal
      :is-open="isPartialModalOpen"
      :mystore-url-list="selectedStoreList"
      @submit="settlementSelect"
      @cancel="modalClose"
    />

    <mystore-settlement-one-modal
      :is-open="isOneModalOpen"
      :mystore-url="selectedStore"
      @submit="settlementEach"
      @cancel="modalClose"
    />

    <mystore-list-filters
      :year-filter.sync="yearFilter"
      :month-filter.sync="monthFilter"
      :search-text-filter.sync="searchTextFilter"
      :state-filter.sync="stateFilter"
      :state-list="stateList"
      @search="refetchData"
      @settlementAll="openSettleAllModal"
      @settlementPartial="openSettlePartialModal"
    />

    <mystore-statistics
      :sale-store-count.sync="saleStoreCount"
      :store-total-amount.sync="storeTotalAmount"
      :sample-total-amount.sync="sampleTotalAmount"
    />

    <b-card
      no-body
    >

      <b-table
        ref="refMystoreListTable"
        :items="fetchMystoreList"
        responsive
        :fields="tableColumns"
        primary-key="idx"
        :sort-by.sync="sortBy"
        show-empty
        empty-text="No matching records found"
        :sort-desc.sync="isSortDirDesc"
        class="position-relative"
        selectable
        select-mode="multi"
        @row-selected="onRowSelected"
      >

        <template #head(idx)>
          <feather-icon
            icon="TrendingUpIcon"
            class="mx-auto"
          />
        </template>

        <template #cell(sale_amount)="data">
          {{ getCommaNumber(data.value, 3) }} 원
        </template>

        <template #cell(settlement_amount)="data">
          {{ getCommaNumber(data.value, 3) }} 원
        </template>

        <template #cell(remain_settlement_amount)="data">
          {{ getCommaNumber(data.value, 3) }} 원
        </template>

        <template #cell(store)="data">
          <b-link
            :to="{ name: 'commerce-order-view', params: { idx: data.item.url_path }}"
            class="font-weight-bold"
          >
            {{ data.item.url_path }}
          </b-link>
        </template>

        <!-- Column: Actions -->
        <template #cell(action)="data">
          <b-button
            size="sm"
            variant="primary"
            :disabled="!data.item.remain_settlement_amount"
            @click="openSettleOneModal(data.item.url_path)"
          >
            정산하기
          </b-button>
        </template>

      </b-table>
      <div class="mx-2 mb-2">
        <b-row>

          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">Showing {{ dataMeta.from }} to {{ dataMeta.to }} of {{ dataMeta.of }} entries</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >

            <b-pagination
              v-model="currentPage"
              :total-rows="totalStore"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon
                  icon="ChevronLeftIcon"
                  size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                  icon="ChevronRightIcon"
                  size="18"
                />
              </template>
            </b-pagination>

          </b-col>

        </b-row>
      </div>
    </b-card>
  </section>

</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BButton,
  BTable,
  BLink,
  BPagination,
} from 'bootstrap-vue'
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
import useMystoreList from './useMystoreList'
import MystoreListFilters from './components/MystoreListFilters.vue'
import MystoreSettlementAllModal from './components/MystoreSettlementAllModal.vue'
import MystoreSettlementPartialModal from './components/MystoreSettlementPartialModal.vue'
import MystoreSettlementOneModal from './components/MystoreSettlementOneModal.vue'
import MystoreStatistics from './components/MystoreStatistics.vue'

import invoiceStoreModule from './orderStoreModule'
import getCommaNumber from '@/hooks/getCommaNumber'

export default {
  components: {
    BCard,
    BRow,
    BCol,
    BButton,
    BTable,
    BLink,
    BPagination,

    MystoreListFilters,
    MystoreSettlementAllModal,
    MystoreSettlementPartialModal,
    MystoreSettlementOneModal,
    MystoreStatistics,
  },
  setup() {
    const INVOICE_APP_STORE_MODULE_NAME = 'app-invoice'

    // Register module
    if (!store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.registerModule(INVOICE_APP_STORE_MODULE_NAME, invoiceStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(INVOICE_APP_STORE_MODULE_NAME)) store.unregisterModule(INVOICE_APP_STORE_MODULE_NAME)
    })

    const {
      fetchMystoreList,
      tableColumns,
      perPage,
      currentPage,
      totalStore,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMystoreListTable,
      stateList,
      selectedStoreList,
      selectedStore,
      saleStoreCount,
      storeTotalAmount,
      sampleTotalAmount,
      isAllModalOpen,
      isPartialModalOpen,
      isOneModalOpen,

      yearFilter,
      monthFilter,
      searchTextFilter,
      stateFilter,

      refetchData,
      settlementAll,
      settlementSelect,
      settlementEach,
      openSettleAllModal,
      openSettlePartialModal,
      openSettleOneModal,
      modalClose,
      onRowSelected,
    } = useMystoreList()

    return {
      fetchMystoreList,
      tableColumns,
      perPage,
      currentPage,
      totalStore,
      dataMeta,
      perPageOptions,
      searchQuery,
      sortBy,
      isSortDirDesc,
      refMystoreListTable,
      stateList,
      selectedStoreList,
      selectedStore,
      saleStoreCount,
      storeTotalAmount,
      sampleTotalAmount,

      yearFilter,
      monthFilter,
      searchTextFilter,
      stateFilter,
      isAllModalOpen,
      isPartialModalOpen,
      isOneModalOpen,

      refetchData,
      settlementAll,
      settlementEach,
      settlementSelect,
      openSettleAllModal,
      openSettlePartialModal,
      openSettleOneModal,
      modalClose,
      onRowSelected,
      getCommaNumber,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}

.invoice-filter-select {
  min-width: 190px;

  ::v-deep .vs__selected-options {
    flex-wrap: nowrap;
  }

  ::v-deep .vs__selected {
    width: 100px;
  }
}

.w-200 {
  width: 200px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
