<template>
  <b-card>
    <b-card-body>
      <b-row>
        <b-col
          v-for="item in statisticsItems"
          :key="item.subtitle"
          md="3"
          sm="6"
          class="mb-2 mb-md-0"
          :class="item.customClass"
        >
          <b-media no-body>
            <b-media-aside
              class="mr-2"
            >
              <b-avatar
                size="48"
                :variant="item.color"
              >
                <feather-icon
                  size="24"
                  :icon="item.icon"
                />
              </b-avatar>
            </b-media-aside>
            <b-media-body class="my-auto">
              <h4 class="font-weight-bolder mb-0">
                {{ item.title }}
              </h4>
              <b-card-text class="font-small-3 mb-0">
                {{ item.subtitle }}
              </b-card-text>
            </b-media-body>
          </b-media>
        </b-col>
      </b-row>
    </b-card-body>
  </b-card>
</template>

<script>
import {
  BCard, BCardText, BCardBody, BRow, BCol, BMedia, BMediaAside, BAvatar, BMediaBody,
} from 'bootstrap-vue'
import { computed } from '@vue/composition-api'
import getCommaNumber from '@/hooks/getCommaNumber'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BCardText,
    BCardBody,
    BMedia,
    BAvatar,
    BMediaAside,
    BMediaBody,
  },
  props: {
    saleStoreCount: {
      type: Number,
      default: 0,
    },
    storeTotalAmount: {
      type: Number,
      default: 0,
    },
    sampleTotalAmount: {
      type: Number,
      default: 0,
    },
  },
  setup(props) {
    const statisticsItems = computed(() => [
      {
        icon: 'HomeIcon',
        color: 'light-info',
        title: getCommaNumber(props.saleStoreCount, 3),
        subtitle: '매출 발생 스토어 수',
        customClass: 'mb-2 mb-xl-0',
      },
      {
        icon: 'TrendingUpIcon',
        color: 'light-primary',
        title: `${getCommaNumber(props.storeTotalAmount, 3)} 원`,
        subtitle: '스토어 매출 (배송비 제외)',
        customClass: 'mb-2 mb-xl-0',
      },
      {
        icon: 'TrendingUpIcon',
        color: 'light-primary',
        title: `${getCommaNumber(props.sampleTotalAmount, 3)} 원`,
        subtitle: '샘플 매출 (배송비 제외)',
        customClass: 'mb-2 mb-sm-0',
      },
    ])
    return {
      statisticsItems,
    }
  },
}
</script>
