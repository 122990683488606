<template>
  <section>
    <b-modal
      hide-footer
      centered
      title="정산"
      :visible="isOpen"
      @hidden="$emit('hidden')"
    >
      <b-card-body>
        <b-card-text>
          {{ mystoreUrl }} 정산을 진행하시겠습니까?
        </b-card-text>
      </b-card-body>
      <b-card-footer>
        <b-button
          class="mr-75"
          variant="primary"
          @click="$emit('submit', mystoreUrl)"
        >
          확인
        </b-button>
        <b-button
          variant="danger"
          @click="$emit('cancel')"
        >
          취소
        </b-button>
      </b-card-footer>
    </b-modal>
  </section>
</template>

<script>
import {
  BModal,
  BCardText,
  BCardBody,
  BCardFooter,
  BButton,
} from 'bootstrap-vue'

export default {
  components: {
    BModal,
    BCardText,
    BCardBody,
    BCardFooter,
    BButton,
  },
  props: {
    mystoreUrl: {
      type: [String, null],
      default: null,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
}
</script>

<style></style>
